import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    isProduction = environment.production;
    showConsoleLog = environment.showConsoleLog;

    log(...data: any[]) {
        if (!this.isProduction && this.showConsoleLog) {
            console.log(data);
        }
    }
}