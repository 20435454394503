import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserNotificationEndpoint } from 'app/main/api/endpoints/user-notification.endpoint';
import { UserNotification } from 'app/main/api/models/resources/user-notification.model';
import { AuthenticationService } from 'app/main/apps/auth/authentication.service';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData: UserNotification[] = [];
  public onApiDataChange: BehaviorSubject<UserNotification[]>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private readonly _userNotificationEndpoint: UserNotificationEndpoint,
    private readonly _authenticationService: AuthenticationService,
  ) {
    this.onApiDataChange = new BehaviorSubject([]);
    // this.getNotificationsData();
    const currentUser = this._authenticationService.currentUserValue;
    if (currentUser) this.getNotificationsData();
  }

  /**
   * Get Notifications Data
   */
  getNotificationsData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._userNotificationEndpoint.listUnreadByUser().subscribe((response) => {
        this.apiData = response.data;
        this.onApiDataChange.next(this.apiData);
        resolve(this.apiData);
      }, reject);
    });
  }
}
