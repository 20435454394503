<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">{{
      notifications.length
    }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div
          class="badge badge-pill badge-light-danger"
          *ngIf="hasNotifications"
        >
          {{ notifications.length }} New
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li
      *ngIf="hasNotifications"
      class="scrollable-container media-list"
      [perfectScrollbar]
    >
      <a
        class="d-flex"
        href="javascript:void(0)"
        *ngFor="let message of notifications"
      >
        <div class="media d-flex align-items-start">
          <!-- <div class="media-left">
            <div class="avatar">
              <img [src]="message.image" alt="avatar" width="32" height="32" />
            </div>
          </div> -->
          <div class="media-body">
            <p
              class="media-heading text-warning font-weight-bolder"
              [innerHTML]="message.subject"
            ></p>
            <small class="text-secondary font-weight-bolder">{{
              message.content
            }}</small>
          </div>
        </div></a
      >
    </li>
    <br />
    <p *ngIf="!hasNotifications" class="media-heading text-center">
      You have no new notifications!
    </p>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <button
        class="btn btn-primary btn-block"
        [disabled]="!hasNotifications"
        (click)="acknowledgeReads()"
      >
        Clear all notifications
      </button>
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>
