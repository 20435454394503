import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'position'
})
export class PositionPipe implements PipeTransform {

	transform(value: any, ...args: unknown[]): string {
		const arr = ['st', 'nd', 'rd', 'th'];
		let pos = '';
		if ((value === 1 || (value % 10) === 1) && value !== 11) {
			pos = arr[0];
		}
		else if ((value === 2 || (value % 10) === 2) && value !== 12) {
			pos = arr[1];
		}
		else if ((value === 3 || (value % 10) === 3) && value !== 13) {
			pos = arr[2];
		}
		else {
			pos = arr[3];
		}
		return value + '' + pos;
	}
}
