import { Injectable } from "@angular/core";
import { LoggerService } from "@core/services/logger.service";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthUser, LoginRequestDto, Role } from "./auth.model";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    currentUser: Observable<AuthUser>;

    private currentUserSubject: BehaviorSubject<AuthUser>;

    constructor(
        private readonly authService: AuthService,
        private readonly _toastr: ToastrService,
        private readonly logger: LoggerService,
    ) {
        this.currentUserSubject = new BehaviorSubject<AuthUser>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    get currentUserValue(): AuthUser {
        return this.currentUserSubject.value;
    }

    get isLawyer(): boolean {
        return this.currentUser && this.currentUserSubject.value.role === Role.Lawyer;
    }

    login(credentials: LoginRequestDto) {
        return this.authService.login(credentials)
            .pipe(
                map((response) => {
                    if (response && response.data.access_token) {
                        const responseUser = response.data.user;
                        this.logger.log("Authentication Service");
                        this.logger.log("=== Actual Response ===");
                        this.logger.log(response);
                        let authUser: AuthUser = { ...responseUser, name: response.data.name, role: Role.User, token: response.data.access_token, avatar: response.data.user.profile_picture ?? "assets/images/noimage.gif" };

                        this.logger.log("=== Local Storage Data ===");
                        this.logger.log(authUser);
                        // Display welcome toast!
                        localStorage.setItem('currentUser', JSON.stringify(authUser));
                        // setTimeout(() => {
                        //     this._toastr.success(
                        //         'You have successfully logged in as a ' +
                        //         'User' +
                        //         ' user to CMS. Now you can start to explore. Enjoy!',
                        //         '👋 Welcome, ' + authUser.name + '!',
                        //         { toastClass: 'toast ngx-toastr', positionClass: 'toast-top-right', closeButton: true }
                        //     );
                        // }, 2500);

                        // notify
                        this.currentUserSubject.next(authUser);
                    }

                    return response;
                })
            );
    }

    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    updateProfileAvatar(photoUrl: string) {
        const authUser: AuthUser = JSON.parse(localStorage.getItem('currentUser'));
        authUser.avatar = photoUrl;

        localStorage.setItem('currentUser', JSON.stringify(authUser));
        this.currentUserSubject.next(authUser);
    }
}
