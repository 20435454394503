import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { UserNotification } from "../models/resources/user-notification.model";

@Injectable({
    providedIn: 'root',
})
export class UserNotificationEndpoint {
    baseUrl = `${environment.apiUrl}/user-notifications`

    constructor(private readonly httpClient: HttpClient) { }

    listByUser() {
        return this.httpClient.get<{ data: UserNotification[] }>(`${this.baseUrl}/user/list`);
    }

    listUnreadByUser() {
        return this.httpClient.get<{ data: UserNotification[] }>(`${this.baseUrl}/user/list-unread`);
    }

    acknowledgeReads() {
        return this.httpClient.get<void>(`${this.baseUrl}/user/acknowledge-reads`);
    }
}