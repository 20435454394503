<div class="fixed-bottom p-1 bg-white">
  <p class="clearfix mb-0 text-center d-print-none">
    <span class="d-block d-md-inline-block mt-25">
      <a class="ml-25" href="https://centuryinformationsystems.com"
        >Powered by Century Information Systems.
      </a>
      <span class="d-none d-sm-inline-block"> All rights Reserved</span>
    </span>
    <span class="float-md-right d-none d-md-block">
      Version <span [class]="'primary'">1.0</span>
    </span>
  </p>
  <div class="d-print-none">
    <app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>
  </div>
</div>
