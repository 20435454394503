export interface RegisterRequestDto {
    full_name: string;
    user_type: string;
    email: string;
    phone_number: string;
    password: string;
    password_confirmation: string;
}

export interface RegisterResponseDto {
    user: {
        email: string;
        created_at: Date;
        updated_at: Date;
        id: number;
    }
}

export interface LoginRequestDto {
    email: string;
    password: string;
}

export interface LoginResponseDto {
    access_token: string;
    token_type: string;
    name: string;
    user: {
        id: number;
        name: string;
        email: string;
        user_type: string;
        is_active: boolean;
        email_verified: boolean;
        profile_picture?: string;
    }
}

export interface AuthUser {
    id: number;
    email: string;
    name: string;
    token: string;
    role: Role;
    avatar?: string;
}
  
﻿export enum Role {
    Lawyer = 'Lawyer',
    User = 'User'
  }