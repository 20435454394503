export const environment = {
  production: true,
  hmr: false,
  showConsoleLog: false,
  apiUrl: 'https://ecmsapi.judiciary.na.gov.ng/api',
  courtTitle: 'HIGH COURT OF NASARAWA',
  stateTitle: 'NASARAWA STATE OF NIGERIA',
  stateTitleSentence: 'Nasarawa State Of Nigeria',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'https://ecmsapi.judiciary.na.gov.ng/api',
  BASE_URL_II: 'https://ecmsapi.judiciary.na.gov.ng/api',
  API_URL: '/',
  currentState: 'Nasarawa State',
  court_bg_path: 'assets/images/court-bg.png',
  court_logo_path: 'assets/images/nas-afid-logo.png',
  paystack_public_key: 'pk_live_b1886cbd84b9eb8a5849e490dbff08a0d27d8b2f',
  remitta_api_key: 'QzAwMDAyNTE5MzV8NTcwODYyNDl8NTk0ZGFkZTE2OWM3N2UyZTMyMzlhYWJiZjM4NjdiZThhYWQyMTUxMWVlMjNiMjdjYmFmODVlNTZlMDFlNTA5ZGZjNDVhOTliM2Q2OGNhZmE0YmI3YzllODBhMTdmNmIxMDc4ZWRlMWM5MDc1OTc4ZGYxMDQ5ZjIxYWU2Mjc0YjQ=',
  remitta_mode: 'test'
};
