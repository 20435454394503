import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { MaintenanceSettingsRequestDto, MaintenanceSettingsResource } from "../models/requests/maintenance-settings-model";


@Injectable({
    providedIn: 'root',
})
export class MaintenanceSettingsEndpoint {
    constructor(private readonly httpClient: HttpClient) { }

    readMaintenanceMode() {
        return this.httpClient.get<{ data: MaintenanceSettingsResource }>(`${environment.apiUrl}/admin/settings/maintenance/1`);
    }
}