import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { UserNotificationEndpoint } from 'app/main/api/endpoints/user-notification.endpoint';
import { UserNotification } from 'app/main/api/models/resources/user-notification.model';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: UserNotification[];
  hasNotifications = false;

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _notificationsService: NotificationsService,
    private readonly _userNotificationEndpoint: UserNotificationEndpoint,
  ) { }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;
      this.hasNotifications = this.notifications.length > 0;
    });
  }

  async acknowledgeReads() {
    if (this.notifications.length > 0) {
      this._userNotificationEndpoint.acknowledgeReads().toPromise()
        .then(() => {
          this._notificationsService.getNotificationsData();
        });
      // Navigate to Notifications page
      return;
    }
  }
}
